import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aefcb7ea = () => interopDefault(import('../pages/401.vue' /* webpackChunkName: "pages/401" */))
const _aea82ae4 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _d2edc710 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _5e87adb8 = () => interopDefault(import('../pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _cf9fb968 = () => interopDefault(import('../pages/approved-partner.vue' /* webpackChunkName: "pages/approved-partner" */))
const _14f496d8 = () => interopDefault(import('../pages/buen-fin.vue' /* webpackChunkName: "pages/buen-fin" */))
const _1b84e805 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6daad721 = () => interopDefault(import('../pages/customer-benefits.vue' /* webpackChunkName: "pages/customer-benefits" */))
const _4ba42a6a = () => interopDefault(import('../pages/hotsale.vue' /* webpackChunkName: "pages/hotsale" */))
const _5cc196ba = () => interopDefault(import('../pages/localflex.vue' /* webpackChunkName: "pages/localflex" */))
const _2a666dea = () => interopDefault(import('../pages/outlet/index.vue' /* webpackChunkName: "pages/outlet/index" */))
const _23a364b1 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7cd458c2 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _762b39bb = () => interopDefault(import('../pages/shopping-cart.vue' /* webpackChunkName: "pages/shopping-cart" */))
const _16afc144 = () => interopDefault(import('../pages/social-auth.vue' /* webpackChunkName: "pages/social-auth" */))
const _fa4751de = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _25517592 = () => interopDefault(import('../pages/visas-and-restrictions.vue' /* webpackChunkName: "pages/visas-and-restrictions" */))
const _01626bd2 = () => interopDefault(import('../pages/wheel-of-fortune.vue' /* webpackChunkName: "pages/wheel-of-fortune" */))
const _5c151ab6 = () => interopDefault(import('../pages/why-choose-us.vue' /* webpackChunkName: "pages/why-choose-us" */))
const _4d200c00 = () => interopDefault(import('../pages/c/mountains-in-mexico.vue' /* webpackChunkName: "pages/c/mountains-in-mexico" */))
const _baf1590e = () => interopDefault(import('../pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara.vue' /* webpackChunkName: "pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara" */))
const _74ebacac = () => interopDefault(import('../pages/landingz/caza-de-auroras-grupo-en-espanol.vue' /* webpackChunkName: "pages/landingz/caza-de-auroras-grupo-en-espanol" */))
const _79299824 = () => interopDefault(import('../pages/f/components/availability/index.vue' /* webpackChunkName: "pages/f/components/availability/index" */))
const _02cf9c8a = () => interopDefault(import('../pages/f/components/availability/components/card.vue' /* webpackChunkName: "pages/f/components/availability/components/card" */))
const _1dbccfd4 = () => interopDefault(import('../pages/user/profile/_tab.vue' /* webpackChunkName: "pages/user/profile/_tab" */))
const _98f4acac = () => interopDefault(import('../pages/user/reservation/_code/edit.vue' /* webpackChunkName: "pages/user/reservation/_code/edit" */))
const _3974b398 = () => interopDefault(import('../pages/public/reservation/_code/_tab.vue' /* webpackChunkName: "pages/public/reservation/_code/_tab" */))
const _f8043714 = () => interopDefault(import('../pages/user/reservation/_code/_tab.vue' /* webpackChunkName: "pages/user/reservation/_code/_tab" */))
const _61f4f048 = () => interopDefault(import('../pages/announcement/_slug.vue' /* webpackChunkName: "pages/announcement/_slug" */))
const _fb423462 = () => interopDefault(import('../pages/f/_permalink.vue' /* webpackChunkName: "pages/f/_permalink" */))
const _4582a18d = () => interopDefault(import('../pages/h/_permalink.vue' /* webpackChunkName: "pages/h/_permalink" */))
const _4afd7748 = () => interopDefault(import('../pages/invitation/_referral_code.vue' /* webpackChunkName: "pages/invitation/_referral_code" */))
const _686bcdee = () => interopDefault(import('../pages/l/_permalink.vue' /* webpackChunkName: "pages/l/_permalink" */))
const _51524c36 = () => interopDefault(import('../pages/magic-link/_token.vue' /* webpackChunkName: "pages/magic-link/_token" */))
const _1b92db09 = () => interopDefault(import('../pages/outlet/_test.vue' /* webpackChunkName: "pages/outlet/_test" */))
const _0c2cb601 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _c2961688 = () => interopDefault(import('../pages/permalink/_slug.vue' /* webpackChunkName: "pages/permalink/_slug" */))
const _1a0ec7a0 = () => interopDefault(import('../pages/landing/_permalink/_test.vue' /* webpackChunkName: "pages/landing/_permalink/_test" */))
const _511135a8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6a37a8b5 = () => interopDefault(import('../pages/_influencer/aventura-rio-nilo.vue' /* webpackChunkName: "pages/_influencer/aventura-rio-nilo" */))
const _f886c7f0 = () => interopDefault(import('../pages/_influencer/explorando-turquia-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/explorando-turquia-con-vuelo" */))
const _bcd63546 = () => interopDefault(import('../pages/_influencer/lo-mejor-peru.vue' /* webpackChunkName: "pages/_influencer/lo-mejor-peru" */))
const _1b9e36c0 = () => interopDefault(import('../pages/_influencer/noches-auroras-boreales-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/noches-auroras-boreales-con-vuelo" */))
const _fa6b2ba8 = () => interopDefault(import('../pages/_influencer/salkantay-trek.vue' /* webpackChunkName: "pages/_influencer/salkantay-trek" */))
const _352ffa8a = () => interopDefault(import('../pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia.vue' /* webpackChunkName: "pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia" */))
const _1976c3b3 = () => interopDefault(import('../components/pages/checkout-layaway/index.vue' /* webpackChunkName: "" */))
const _4e720597 = () => interopDefault(import('../components/pages/experience-package/ppcv1.vue' /* webpackChunkName: "" */))
const _e6c5ff6a = () => interopDefault(import('../components/pages/experience-package/index.vue' /* webpackChunkName: "" */))
const _bb8ce3d2 = () => interopDefault(import('../components/pages/phocuswright/index.vue' /* webpackChunkName: "" */))
const _535971ca = () => interopDefault(import('../components/pages/outlet-viaja-vuela/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/401",
    component: _aefcb7ea,
    name: "401"
  }, {
    path: "/404",
    component: _aea82ae4,
    name: "404"
  }, {
    path: "/about-us",
    component: _d2edc710,
    name: "about-us"
  }, {
    path: "/announcement",
    component: _5e87adb8,
    name: "announcement"
  }, {
    path: "/approved-partner",
    component: _cf9fb968,
    name: "approved-partner"
  }, {
    path: "/buen-fin",
    component: _14f496d8,
    name: "buen-fin"
  }, {
    path: "/contact-us",
    component: _1b84e805,
    name: "contact-us"
  }, {
    path: "/customer-benefits",
    component: _6daad721,
    name: "customer-benefits"
  }, {
    path: "/hotsale",
    component: _4ba42a6a,
    name: "hotsale"
  }, {
    path: "/localflex",
    component: _5cc196ba,
    name: "localflex"
  }, {
    path: "/outlet",
    component: _2a666dea,
    name: "outlet"
  }, {
    path: "/privacy-policy",
    component: _23a364b1,
    name: "privacy-policy"
  }, {
    path: "/search",
    component: _7cd458c2,
    name: "search"
  }, {
    path: "/shopping-cart",
    component: _762b39bb,
    name: "shopping-cart"
  }, {
    path: "/social-auth",
    component: _16afc144,
    name: "social-auth"
  }, {
    path: "/terms-and-conditions",
    component: _fa4751de,
    name: "terms-and-conditions"
  }, {
    path: "/visas-and-restrictions",
    component: _25517592,
    name: "visas-and-restrictions"
  }, {
    path: "/wheel-of-fortune",
    component: _01626bd2,
    name: "wheel-of-fortune"
  }, {
    path: "/why-choose-us",
    component: _5c151ab6,
    name: "why-choose-us"
  }, {
    path: "/c/mountains-in-mexico",
    component: _4d200c00,
    name: "c-mountains-in-mexico"
  }, {
    path: "/landing/tours-en-vancouver-con-vuelos-desde-guadalajara",
    component: _baf1590e,
    name: "landing-tours-en-vancouver-con-vuelos-desde-guadalajara"
  }, {
    path: "/landingz/caza-de-auroras-grupo-en-espanol",
    component: _74ebacac,
    name: "landingz-caza-de-auroras-grupo-en-espanol"
  }, {
    path: "/f/components/availability",
    component: _79299824,
    name: "f-components-availability"
  }, {
    path: "/f/components/availability/components/card",
    component: _02cf9c8a,
    name: "f-components-availability-components-card"
  }, {
    path: "/user/profile/:tab?",
    component: _1dbccfd4,
    name: "user-profile-tab"
  }, {
    path: "/user/reservation/:code?/edit",
    component: _98f4acac,
    name: "user-reservation-code-edit"
  }, {
    path: "/public/reservation/:code?/:tab?",
    component: _3974b398,
    name: "public-reservation-code-tab"
  }, {
    path: "/user/reservation/:code?/:tab?",
    component: _f8043714,
    name: "user-reservation-code-tab"
  }, {
    path: "/announcement/:slug",
    component: _61f4f048,
    name: "announcement-slug"
  }, {
    path: "/f/:permalink?",
    component: _fb423462,
    name: "f-permalink"
  }, {
    path: "/h/:permalink?",
    component: _4582a18d,
    name: "h-permalink"
  }, {
    path: "/invitation/:referral_code?",
    component: _4afd7748,
    name: "invitation-referral_code"
  }, {
    path: "/l/:permalink?",
    component: _686bcdee,
    name: "l-permalink"
  }, {
    path: "/magic-link/:token?",
    component: _51524c36,
    name: "magic-link-token"
  }, {
    path: "/outlet/:test",
    component: _1b92db09,
    name: "outlet-test"
  }, {
    path: "/p/:slug?",
    component: _0c2cb601,
    name: "p-slug"
  }, {
    path: "/permalink/:slug?",
    component: _c2961688,
    name: "permalink-slug"
  }, {
    path: "/landing/:permalink?/:test?",
    component: _1a0ec7a0,
    name: "landing-permalink-test"
  }, {
    path: "/",
    component: _511135a8,
    name: "index"
  }, {
    path: "/:influencer/aventura-rio-nilo",
    component: _6a37a8b5,
    name: "influencer-aventura-rio-nilo"
  }, {
    path: "/:influencer/explorando-turquia-con-vuelo",
    component: _f886c7f0,
    name: "influencer-explorando-turquia-con-vuelo"
  }, {
    path: "/:influencer/lo-mejor-peru",
    component: _bcd63546,
    name: "influencer-lo-mejor-peru"
  }, {
    path: "/:influencer/noches-auroras-boreales-con-vuelo",
    component: _1b9e36c0,
    name: "influencer-noches-auroras-boreales-con-vuelo"
  }, {
    path: "/:influencer/salkantay-trek",
    component: _fa6b2ba8,
    name: "influencer-salkantay-trek"
  }, {
    path: "/:influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia",
    component: _352ffa8a,
    name: "influencer-santorini-naxos-y-atenas-en-una-aventura-por-grecia"
  }, {
    path: "/:type(continente|pais|ciudad|categoria)/:value/:tab(viajes|tours)?/:days?",
    component: _7cd458c2,
    name: "search-type"
  }, {
    path: "/:type(checkout|layaway|fast-checkout)",
    component: _1976c3b3,
    name: "checkout-layaway"
  }, {
    path: "/:type(tours|paquete)/:permalink/:test(ppcv1)",
    component: _4e720597,
    name: "product-permalink-ppcv1"
  }, {
    path: "/:type(tours|paquete)/:permalink",
    component: _e6c5ff6a,
    name: "product-permalink"
  }, {
    path: "/:type(general-phocuswright-2022|phochuswright-2022-travel-tech-leaders|phocuswright-2022-invite-only)",
    component: _bb8ce3d2,
    name: "phocuswright"
  }, {
    path: "/expo-viaja-vuela-2023",
    component: _535971ca,
    name: "expo-viaja-vuela-2023"
  }, {
    path: "*",
    redirect: "/404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
